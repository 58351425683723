<template>
  <div class="PERInformation">
    <div class="PERInformation-head">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>机构管理</el-breadcrumb-item>
        <el-breadcrumb-item>教付保机构管理</el-breadcrumb-item>
        <el-breadcrumb-item>教付保机构详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-dialog :visible.sync="setadders" title="地址">
      <!-- <xmap
        width="900px"
        height="500px"
        :id="id.id"
        :lnglat="[120.219834, 30.261089]"
        @location="location"
      ></xmap> -->
    </el-dialog>

    <!-- 机构信息 -->
    <div class="organization">
      <div class="left">
        <h2>机构信息</h2>
        <img :src="id.mechanism_logo" alt="" />
      </div>
      <div class="right">
        <ul>
          <li>
            机构名称: <span>{{ id.mechanism_name }}</span>
          </li>
          <li>
            联系电话: <span>{{ id.mechanism_telephone }}</span>
          </li>
          <li>
            经营类别: <span>{{ id.categories_child }}</span>
          </li>
          <li>
            机构地址: <span>{{ id.mechanism_addr }}</span>
          </li>
          <li>
            支付宝账号: <span>{{ id.payee_logon_id }}</span>
          </li>
          <li>
            负责人姓名: <span>{{ id.contacts }}</span>
          </li>
          <li>
            负责人电话 :<span>{{ id.contact_telephone }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 添加表单弹窗 -->
    <!-- Form 资料修改表单-->
    <el-button
      type="warning"
      @click="dialogFormVisible1 = true"
      class="blt"
      plain
      >修改资料</el-button
    >
    <el-dialog title="资料修改" :visible.sync="dialogFormVisible1">
      <div style="margin: 20px"></div>
      <el-form :label-position="labelPosition" label-width="100px" :model="id">
        <el-form-item label="机构名称">
          <el-input v-model="id.mechanism_name"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="id.mechanism_telephone"></el-input>
        </el-form-item>
        <el-form-item label="机构简介">
          <el-input v-model="id.introduction_content"></el-input>
        </el-form-item>
        <el-form-item label="机构经度">
          <el-input v-model="id.longitude"></el-input>
        </el-form-item>
        <el-form-item label="机构纬度">
          <el-input v-model="id.latitude"></el-input>
        </el-form-item>
        <el-form-item label="机构类别" prop="categories_child">
          <el-select v-model="id.categories_child" placeholder="请选择机构类别">
            <el-option
              v-for="item in ListPageChild"
              :key="item.value"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构子类别" prop="categories_child">
          <el-select v-model="id.categories_child" placeholder="请选择机构子类别">
            <el-option
              v-for="item in ListPageChild_child"
              :key="item.value"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构地址">
          <el-input v-model="id.mechanism_addr"></el-input>
        </el-form-item>
        <el-form-item label="支付宝账号">
          <el-input v-model="id.payee_logon_id"></el-input>
        </el-form-item>
        <el-form-item label="负责人姓名">
          <el-input v-model="id.contacts"></el-input>
        </el-form-item>
        <el-form-item label="负责人电话">
          <el-input v-model="id.contact_telephone"></el-input>
        </el-form-item>
        <div class="organizationimg">
          <span
            >机构标志:
            <div class="my-upload">
                  <avatar-uploader @getImgUrl="Uploadcourintroductioncover" :clear="if_clear" :url='id.mechanism_logo'></avatar-uploader>
            </div>
          </span>
          <span
            >营业执照:
            <div class="my-upload">
                  <avatar-uploader @getImgUrl="Uploadcourintroductioncover1" :clear="if_clear" :url='id.support_means'></avatar-uploader>
            </div>
          </span>
          <span
            >机构门面:
            <div class="my-upload">
                  <avatar-uploader @getImgUrl="Uploadcourintroductioncover2" :clear="if_clear" :url='id.facade_view'></avatar-uploader>
            </div>
          </span>
          <span class="span">
            <h3>其他:</h3>
            <div class="my-uploads">
                  <avatar-uploader @getImgUrl="Uploadcourintroductioncover30" :clear="if_clear" :url='piclist[0]'></avatar-uploader>
                  <avatar-uploader @getImgUrl="Uploadcourintroductioncover31" :clear="if_clear" :url='piclist[1]'></avatar-uploader>
                  <avatar-uploader @getImgUrl="Uploadcourintroductioncover32" :clear="if_clear" :url='piclist[2]'></avatar-uploader>
            </div>
          </span>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setadders = !setadders"
          >修改地址</el-button
        >
        <el-button @click="dialogFormVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="add">修改</el-button>
      </div>
    </el-dialog>
    <!-- 机构营业执照 -->
    <div class="picture">
      <div class="picture1">
        <h2>机构营业执照</h2>
        <img :src="id.support_means" alt="" />
      </div>
      <div class="picture2">
        <h2>机构门面</h2>
        <img :src="id.facade_view" alt="" />
      </div>
      <div class="picture3">
        <h2>其他照片</h2>
        <div
          v-for="(item, index) in id.introduction_pic.split(',')"
          :key="index"
          style="width: 163px"
        >
          <img :src="item" alt="" style="width: 163px" />
        </div>
      </div>
    </div>
    <!--  中间条横-->
    <div class="box"></div>
    <!-- 在售课程 -->
    <div class="PERInformation-table">
      <!-- 添加表单弹窗 -->
      <!-- dialog 添加课程弹框-->
      <div>
        <el-button
          type="warning"
          v-if="selectedBar == 0"
          @click="dialogFormVisible = true"
          class="blt1"
          plain
          >添加课程</el-button
        >
        <el-dialog title="添加正式课程" :visible.sync="dialogFormVisible">
          <el-form :model="OfficialCourses">
            <el-form-item label="课程封面" :label-width="formLabelWidth">
              <div class="my-upload">
                  <avatar-uploader @getImgUrl="Uploadcourintroductioncover4" :clear="if_clear" :url='OfficialCourses.face_url'></avatar-uploader>
              </div>
            </el-form-item>
            <el-form-item label="课程详情图片" :label-width="formLabelWidth">
              <div class="my-upload">
                  <avatar-uploader @getImgUrl="Uploadcourintroductioncover5" :clear="if_clear" :url='OfficialCourses.introduction_url'></avatar-uploader>
              </div>
            </el-form-item>
            <el-form-item
              :required="true"
              :hide-required-asterisk="true"
              label="课程类别"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="OfficialCourses.categories"
                placeholder="请选择课程类别"
              >
                <el-option
                  v-for="(item, index) in ListPageChild"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :required="true"
              :hide-required-asterisk="true"
              label="课程子类别"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="OfficialCourses.categories_child"
                placeholder="请选择课程子类别"
              >
                <el-option
                  v-for="(item, index) in ListPageChild_child"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="课程类型" :label-width="formLabelWidth">
              <el-radio-group v-model="OfficialCourses.commodity_type">
                <el-radio :label="1">优学A卡</el-radio>
                <el-radio :label="2">优学B卡</el-radio>
                <el-radio :label="3">标准课</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="标题" :label-width="formLabelWidth">
              <el-input
                v-model="OfficialCourses.title"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="标签" :label-width="formLabelWidth">
              <el-input
                v-model="OfficialCourses.label"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="适用年龄" :label-width="formLabelWidth">
              <el-input
                v-model="OfficialCourses.apply_age"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="使用期限（月）" :label-width="formLabelWidth">
              <el-input
                v-model="OfficialCourses.duration"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="课节" :label-width="formLabelWidth">
              <el-input
                v-model="OfficialCourses.course_num"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="课时长/分" :label-width="formLabelWidth">
              <el-input
                v-model="OfficialCourses.length_of_lesson"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="套餐价格" :label-width="formLabelWidth">
              <el-input
                v-model="OfficialCourses.amout"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="活动价格" :label-width="formLabelWidth">
              <el-input
                v-model="OfficialCourses.discount_amout"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="课程简介" :label-width="formLabelWidth">
              <el-input
                v-model="OfficialCourses.introduction_content"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="OfficialCoursesChange()"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>

      <!-- dialog 添加老师弹框-->
      <div>
        <el-button
          v-if="selectedBar == 1"
          type="warning"
          @click="dialog2 = true"
          class="blt1"
          plain
          >添加老师</el-button
        >
        <el-dialog title="添加老师" :visible.sync="dialog2">
          <el-form :model="AddTeacher">
            <el-form-item label="证件照" :label-width="formLabelWidth">
              <div class="my-upload">
                  <avatar-uploader @getImgUrl="Uploadcourintroductioncover6" :clear="if_clear" :url='AddTeacher.avatar'></avatar-uploader>
              </div>
            </el-form-item>

            <el-form-item label="姓名" :label-width="formLabelWidth">
              <el-input
                v-model="AddTeacher.nick_name"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="教龄" :label-width="formLabelWidth">
              <el-input
                v-model="AddTeacher.master_age"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="性别" :label-width="formLabelWidth">
              <el-input v-model="AddTeacher.sex" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="手机号" :label-width="formLabelWidth">
              <el-input
                v-model="AddTeacher.mobile"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="专长" :label-width="formLabelWidth">
              <el-input
                v-model="AddTeacher.specialty"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="科目" :label-width="formLabelWidth">
              <el-input
                v-model="AddTeacher.categories_child"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="老师简介" :label-width="formLabelWidth">
              <el-input
                v-model="AddTeacher.introduction_content"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="设置账号" :label-width="formLabelWidth">
              <el-input
                v-model="AddTeacher.login_name"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="登陆密码" :label-width="formLabelWidth">
              <el-input
                v-model="AddTeacher.login_pass"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialog2 = false">取 消</el-button>
            <el-button type="primary" @click="AddTeacherChange()"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>

      <!-- list主体表单 -->
      <div class="tabar">
        <button
          :class="{ selected: index == selectedBar }"
          @click="selectdIndex(index)"
          v-for="(item, index) in tabar"
          :key="index"
        >
          {{ item }}
        </button>
      </div>
      <div class="ontents" v-if="selectedBar == 0">
        <div class="topup-title">课程列表</div>
      </div>
      <div class="ontents" v-if="selectedBar == 1">
        <div class="topup-title">老师列表</div>
      </div>
      <div class="contentList">
        <div v-if="selectedBar == 0" class="paging">
          <el-table
            border
            ref="multipleTable"
            :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
            :data="recording"
            tooltip-effect="dark"
            style="width: 100%"
            @filter-change="filterChange"
          >
            <el-table-column
              prop="title"
              align="center"
              label="课程标题"
            ></el-table-column>
            <el-table-column
              prop="categories"
              align="center"
              label="类目"
            ></el-table-column>
            <el-table-column
              prop="categories"
              align="center"
              label="课程类型"
            ></el-table-column>
            <el-table-column
              prop="type"
              align="center"
              label="排课类型"
            ></el-table-column>
            <el-table-column
              prop="course_num"
              align="center"
              label="课节数"
            ></el-table-column>
            <el-table-column
              prop="create_time"
              align="center"
              label="创建时间"
            ></el-table-column>

            <el-table-column prop="status" label="状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1" style="color: #23c3c4"
                  >待审核</span
                >
                <span v-else-if="scope.row.status == 2" style="color: #b8b8b8"
                  >已通过</span
                >
                <span v-else-if="scope.row.status == 3" style="color: #ff6262"
                  >已拒绝</span
                >
                <span v-else-if="scope.row.status == 4" style="color: #ff6262"
                  >注销</span
                >
                <span v-else style="color: #ff6262">冻结</span>
              </template>
            </el-table-column>

            <el-table-column align="center" fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status == 2"
                  @click="UnUploaded(scope.row)"
                  type="text"
                  size="small"
                  >下架</el-button
                >
                <el-button
                  v-else
                  @click="Uploaded(scope.row)"
                  type="text"
                  size="small"
                  >上架</el-button
                >

                <el-button
                  @click="handleClick(scope.row, '课程')"
                  type="text"
                  size="small"
                  >修改</el-button
                >
                <el-button
                  @click="tuijian(scope.row)"
                  type="text"
                  size="small"
                  v-if="scope.row.is_recommend == false"
                  >推荐</el-button
                >
                <el-button
                  @click="Untuijian(scope.row)"
                  v-else
                  type="text"
                  size="small"
                  >取消推荐</el-button
                >
                <!-- <el-button
                  @click="changego(scope.row)"
                  type="text"
                  size="small"
                  v-if="scope.row.is_recommend == 0"
                  >参加活动</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
          <!--分页-->
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
        <div v-if="selectedBar == 1" class="paging">
          <el-table
            border
            ref="multipleTable"
            :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
            :data="teacherData"
            tooltip-effect="dark"
            style="width: 100%"
            @filter-change="filterChange"
          >
            <el-table-column
              prop="full_name"
              align="center"
              label="姓名"
            ></el-table-column>
            <el-table-column align="center" label="证件照">
              <template slot-scope="scope">
                <img :src="scope.row.photo" style="height: 43px" />
              </template>
            </el-table-column>
            <el-table-column
              prop="master_age"
              align="center"
              label="教龄"
            ></el-table-column>
            <el-table-column
              prop="categories_child"
              align="center"
              label="科目"
            ></el-table-column>

            <el-table-column prop="status" label="状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1" style="color: #23c3c4"
                  >待审核</span
                >
                <span v-else-if="scope.row.status == 2" style="color: #b8b8b8"
                  >已通过</span
                >
                <span v-else-if="scope.row.status == 3" style="color: #ff6262"
                  >已拒绝</span
                >
                <span v-else-if="scope.row.status == 4" style="color: #ff6262"
                  >注销</span
                >
                <span v-else style="color: #ff6262">冻结</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="is_recommend"
              align="center"
              fixed="right"
              label="操作"
            >
              <template slot-scope="scope" v-if="selectedBar == 1">
                <el-button
                  @click="handleClick(scope.row, '教师')"
                  type="text"
                  size="small"
                  >修改</el-button
                >
                <el-button
                  @click="Separation(scope.row)"
                  type="text"
                  size="small"
                  >离职</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!--分页-->
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next, jumper"
            :total="total2"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import xmap from "@/components/Amap";
export default {
//   components: { xmap },
  name: "PERInformation",
  data() {
    return {
        if_clear:true,
      id: JSON.parse(this.$route.query.id),
      setadders: false,
      piclist: [],
      // 选中的
      value: "",
      // 添加课程
      // 图片上传
      domain: "http://up-z0.qiniup.com",
      upload_qiniu_addr: "http://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: "",
      },
      // 弹框表单
      labelPosition: "right",
      ruleForm: {
        contact_telephone: "",
        contacts: "",
        contacts_title: "",
        introduction_content: "",
        status: "2",
        introduction_pic: "",
        latitude: "",
        longitude: "",
        mechanism_addr: "",
        mechanism_advantage: "",
        mechanism_language: "英语",
        mechanism_logo: "",
        mechanism_name: "",
        mechanism_telephone: "",
        support_means: "",
        // user_id: 0,
        type: "teach_paypal",
        recommender_id: "",
        categories: "",
        categories_child: "",
        payee_logon_id: "",
        is_support_teach_paypal: "",
        facade_view: "",
      },
      tabar: ["课程", "老师"],
      selectedBar: 0,
      recording: [], //课程数据
      teacherData: [], //教师数据
      ListPageChild: [],
      ListPageChild_child: [],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      total2: 0,
      // 添加表单弹窗
      dialogFormVisible1: false, //修改资料
      dialogFormVisible: false, //课程类型1
      dialogFormVisible2: false, //课程类型2
      dialog2: false, //添加老师
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "120px",
      // 选中的
      OfficialCourses: {
        amout: 0, //价格
        appointment_type: "appointment", //排课类型
        course_num: 1, //课程数
        categories: "", //类目
        face_url: "", //封面
        introduction_content: "1", //课程简介
        introduction_url: "", //图片简介
        label: "1", //标签
        title: "1", //标题
        duration: 0, //使用期限 有效期
        discount_amout: 0, //默认折扣价
      }, //正式课程

      AddTeacher: {
        login_pass: "",
        login_name: "",
        categories_child: "",
        nick_name: "",
        avatar: "",
        mobile: "",
        sex: "",
        specialty: "",
        introduction_content: "",
        master_age: "",
      }, //添加老师
    };
  },
  mounted() {
    this.handleCurrentChange(1);
    this.gettoken(); // 请求token
    this.GetListPageChild();
    this.GetListPageChild_child();
    this.piclist = this.id.introduction_pic.split(",");
  },
  methods: {
    GetListPageChild() {
      let url =
        
        "/user/mechanismCategory/queryListPageChild?type=1&status=2";
      this.$axios.get(url).then((res) => {
        console.log(res);
        this.ListPageChild = res.data.data;
      });
    },
      GetListPageChild_child() {
      let url =
        
        "/user/mechanismCategory/queryListPageChild?type=2&status=2";
      this.$axios.get(url).then((res) => {
        console.log(res);
        this.ListPageChild_child = res.data.data;
      });
    },
    location(point, address) {
      alert(`坐标：${point[0]},${point[1]} - 地址：${address}`);
    },
    // changego(row) {
    //   //参加活动
    //   this.$router.push({
    //     name: "InstitutionalEventsManagement",
    //     query: {
    //       id: JSON.stringify(row),
    //     },
    //   });
    // },

    //添加课程
    OfficialCoursesChange() {
      let commodity_type;
      if (this.OfficialCourses.commodity_type == 1) {
        commodity_type = "A卡";
      } else if (this.OfficialCourses.commodity_type == 2) {
        commodity_type = "B卡";
      } else {
        commodity_type = "";
      }
      let url =  "/user/masterSetPrice/insert";
      let data = {
        amout: this.OfficialCourses.amout,
        appointment_type: this.OfficialCourses.appointment_type,
        connect_peoplenum: 10,
        categories: this.OfficialCourses.categories,
        course_num: this.OfficialCourses.course_num,
        discount_amout: this.OfficialCourses.discount_amout,
        face_url: this.OfficialCourses.face_url,
        introduction_content: this.OfficialCourses.introduction_content,
        introduction_url: this.OfficialCourses.introduction_url,
        label: this.OfficialCourses.label,
        mechanism_id: this.id.id,
        status: 2,
        title: this.OfficialCourses.title,
        type: "mechanism_offline",
        duration: this.OfficialCourses.duration,
        default_discount_price: this.OfficialCourses.discount_amout,
        length_of_lesson: this.OfficialCourses.length_of_lesson,
        apply_age: this.OfficialCourses.apply_age,
        commodity_type,
      };

      this.$axios.post(url, data).then((res) => {});
      this.dialogFormVisible = false;
    },

    //添加机构老师
    AddTeacherChange() {
      let sex;
      if (this.AddTeacher.sex == "男") {
       sex = 1;
      } else {
       sex = 2;
      }
      let url =  "/user/user/insertMechanismMaster";
      let data = {
        master_age: this.AddTeacher.master_age,
        login_pass: this.AddTeacher.login_pass,
        login_name: this.AddTeacher.login_name,
        nick_name: this.AddTeacher.nick_name,
        register_platform: "teach_paypal",
        mechanism_id: this.id.id,
        avatar: this.AddTeacher.avatar,
        mobile: this.AddTeacher.mobile,
        sex: sex,
        specialty: this.AddTeacher.specialty,
        categories_child: this.AddTeacher.categories_child,
        introduction_content: this.AddTeacher.introduction_content,
      };
      this.$axios.post(url, data).then((res) => {
        this.dialog2=!this.dialog2
        this.GetTeacherList();
      });
    },
    //图片操作
    beforeUploadGetKeys(file) {
      this.gettoken();
    },
    // 机构logo图片
    Uploadcourintroductioncover(file) {
      this.id.mechanism_logo = file
    },
    // 机构营业执照图片
    Uploadcourintroductioncover1(file) {
      this.id.support_means = file
      // console.log(this.id.support_means, "我按时");
    },
    // 机构门面图片
    Uploadcourintroductioncover2(file) {
      this.id.facade_view = file
    },
    // 机构其他图片
    Uploadcourintroductioncover30(file) {
      this.piclist[0] = file
    },
    Uploadcourintroductioncover31(file) {
      this.piclist[1] = file
    },
    Uploadcourintroductioncover32(file) {
      this.piclist[2] = file
    },
    //添加正式课程封面
    Uploadcourintroductioncover4(file) {
      this.OfficialCourses.face_url = file
    },
    Uploadcourintroductioncover5(file) {
      this.OfficialCourses.introduction_url = file
    },

    //添加老师证件照
    Uploadcourintroductioncover6(file) {
      this.AddTeacher.avatar = file
    },
    // 获取token
    gettoken() {
      let _this = this;
      var timestamp = new Date().getTime();
      let data = {
        key: timestamp,
        type: "pic",
      };
      _this
        .$axios({
          method: "post",
          url: "http://101.37.66.151:8768/eg-api/push/upload/getToken",
          data: data,
        })
        .then((res) => {
          _this.QiNiYunL.token = res.data.data;
          _this.QiNiYunL.key = data.key;
          // console.log(_this.QiNiYunL, " _this.QiNiYunL _this.QiNiYunL");
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    // 修改资料//修改机构信息
    add() {
      let str = "";
      for (let i = 0; i < this.piclist.length; i++) {
        str += this.piclist[i] + ",";
      }
      this.dialogFormVisible1 = false;
      let data = {
        id: this.id.id,
        contact_telephone: this.id.contact_telephone,
        contacts: this.id.contacts,
        contacts_title: this.id.contacts_title,
        introduction_content: this.id.jigoujianjie,
        status: "2",
        introduction_pic: str,
        latitude: this.id.latitude,
        longitude: this.id.longitude,
        mechanism_addr: this.id.mechanism_addr,
        mechanism_advantage: this.id.mechanism_advantage,
        mechanism_logo: this.id.mechanism_logo,
        mechanism_name: this.id.mechanism_name,
        mechanism_telephone: this.id.mechanism_telephone,
        support_means: this.id.support_means,
        user_id: "10689",
        type: "teach_paypal",
        categories: this.id.categories,
        categories_child: this.id.categories_child,
        payee_logon_id: this.id.payee_logon_id,
        is_support_teach_paypal: this.id.is_support_teach_paypal,
        facade_view: this.id.facade_view,
      };
      this.$axios({
        method: "post",
        url:  "/user/mastermechanism/update",
        data: data,
      })
        .then((res) => {
          if (res.data.code == 0) {
            //  console.log(res);
            //成功
            this.$message({
              message: "添加成功",
              type: "success",
            });
          }
        })
        .catch((error) => {
          //  console.log(error);
        });
    },
    // list表单

    filterChange(filterObj) {
      //  console.log(filterObj);
    },

    //课程列表
    GetList() {
      this.$axios({
        method: "get",
        url:  "/user/masterSetPrice/queryByEs",
        params: {
          type: "mechanism_offline",
          pageSize: 10,
          currentPage: this.currentPage,
          is_support_teach_paypal: true,
          status: "",
          mechanism_id: this.id.id,
        },
      }).then((res) => {
        // console.log(res, "所有课程");
        this.recording = res.data.data.rows;
        this.total = res.data.data.total;
      });
    },

    //机构老师列表
    GetTeacherList() {
      let url =
         "/user/masterInfo/queryManagerListPage";
      let params = {
        type: "teach_paypal",
        pageSize: "10",
        currentPage: "1",
        mechanism_id: this.id.id,
      };
      this.$axios({ method: "get", url, params }).then((res) => {
        // console.log(res, "机构老师列表");
        this.teacherData = res.data.data.rows;
        this.total2 = res.data.data.total;
      });
    },

    handleSizeChange(val) {
      //  console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //  console.log(`当前页: `);
      this.currentPage = val;
      this.GetList();
      this.GetTeacherList();
    },
    selectdIndex(index) {
      this.selectedBar = index;
      this.GetList();
    },
    //上架
    Uploaded(row) {
      // console.log(row.id);
      let url =  "/user/masterSetPrice/update";
      let data = {
        id: row.id,
        status: 2,
      };
      this.$axios.post(url, data).then((res) => {
        alert("已上架");
        this.GetList();
      });
    },
    //下架
    UnUploaded(row) {
      // console.log(row.id);
      let url =  "/user/masterSetPrice/update";
      let data = {
        id: row.id,
        status: 4,
      };
      this.$axios.post(url, data).then((res) => {
        alert("已下架");
        this.GetList();
      });
    },
    //推荐
    tuijian(row) {
      // console.log(row.id);
      let url =  "/user/masterSetPrice/update";
      let data = {
        id: row.id,
        is_recommend: true,
      };
      this.$axios.post(url, data).then((res) => {
        alert("推荐");
        this.GetList();
      });
    },
    //取消推荐
    Untuijian(row) {
      // console.log(row.id);
      let url =  "/user/masterSetPrice/update";
      let data = {
        id: row.id,
        is_recommend: false,
      };
      this.$axios.post(url, data).then((res) => {
        alert("取消推荐");
        this.GetList();
      });
    },
    //修改
    handleClick(row, Identity) {
      this.$router.push({
        name: "TeachFuBaocourseDetails",
        query: {
          id: JSON.stringify(row),
          Identity,
          Institutions: JSON.stringify(this.id),
        },
      });
    },
    //离职
    Separation(row) {
      // console.log(row.id);
      let url =  "/user/masterInfo/update";
      let data = {
        id: row.id,
        status: 4,
      };
      this.$axios.post(url, data).then((res) => {
        alert("已离职");
        this.GetTeacherList();
      });
    },
  },
};
</script>
<style scoped lang="less">
@import '../../assets/css/noTutop.less';

.boxamp {
  z-index: 10086;
  position: absolute;
}

/* .PERInformation{
    background: #EAEAEA!important;
} */
/* 列表 */
.red {
  color: #000;
}

.paging {
  position: relative;
  margin-top: 60px;
  margin-bottom: 50px;
}

.paging>.el-pagination {
  text-align: right;
}

.ontents {
  margin-top: 50px;
}

.topup-title {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: #000;
  font-weight: bold;
}

.tabar {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-top: -90px;
  position: relative;

  button {
    height: 40px;
    width: 104px;
    background: none;
    border: none;
    color: #666;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    font-weight: Regular;
    margin-left: 10px;
    cursor: pointer;
  }

  .selected {
    border-bottom: 3px solid #47CECF;
    color: #47cecf;
    font-weight: 700;
  }
}

/* 修改图片资料 */
.organizationimg {
  display: flex;
  flex-wrap: wrap;
  height: 150px;
}

.organizationimg span {
  width: 110px;
  height: 150px;
  margin-left: 15px;
  padding: 0;
  box-sizing: border-box;
}

.organizationimg .span {
  width: 302px;
  height: 110px;
}

.organizationimg .span h3 {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.organizationimg span img {
  width: 100px;
  height: 100px;
}

/* 图片上传 */
.my-upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  display: block;
}

.my-upload>.el-icon-plus:before {
  display: none;
}

.my-uploads {
  width: 300px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  display: block;
}

.avatar-uploader1 {
  display: inline;
  width: 100px;
  height: 100px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader-icon {
  position: relative;
  color: #ffffff;
  width: 100px;
  height: 100px;
}

.PERInformation {
  position: relative;
}

/* 上架下架 */
.ul .action {
  color: #FF7300;
  font-size: 20px;
  font-family: 'MicrosoftYaHei-Bold';
  font-weight: 600;
  float: left;
}

.ul .action span {
  position: absolute;
  top: 35px;
  right: 5px;
  background-color: #FF7300;
  width: 48px;
  height: 5px;
  float: left;
  border-radius: 10px;
}

.ul li {
  position: relative;
  margin-left: 50px;
}

/* 中间条横 */
.box {
  width: 100%;
  height: 20px;
  margin-top: 100px;
  background-color: #EAEAEA;
}

/* 机构信息 */
.organization {
  padding: 0;
  /* border: 1px solid #ff6c60; */
  width: 1200px;
  height: 200px;
}

.blt {
  position: absolute;
  top: 120px;
  right: 52px;
}

.left {
  float: left; /* 左浮动 */
  width: 200px;
  height: 200px;
  /* border: 1px solid green; */
}

.left h2 {
  font-size: 20px;
  margin-top: 0;
}

.left img {
  width: 150px;
  height: 150px;
  margin-top: -5px;
  margin-left: 20px;
  overflow: hidden;
}

.right {
  position: relative;
  overflow: hidden;
  height: 200px;
  /* border: 1px solid gold; */
  word-break: break-all; /* 强制让内容换行 */
}

.right li {
  float: left;
  margin-left: 100px;
  margin-top: 20px;
  color: #434343;
}

.right li span {
  color: #b5b5b5;
  margin-left: 10px;
}

/* .organization label{
    float: left;
    margin-left: 20px;
} */
/* 机构照片 */
.picture {
  position: relative;
  width: 100%;
  height: 200px;
}

.picture h2 {
  font-size: 20px;
}

.picture1 img {
  width: 240px;
  height: 160px;
  border-radius: 19px;
}

.picture2 img {
  width: 190px;
  height: 163px;
  border-radius: 19px;
}

.picture3 img {
  border-radius: 19px;
}

.picture3 {
  position: absolute;
  left: 45%;
  width: 40%;
}

.picture div {
  float: left;
  color: #434343;
}

.picture1 {
  position: absolute;
  left: 0px;
  display: inline block;
}

.picture2 {
  margin-left: 300px;
}

/* 在售课程 */
.PERInformation-table {
  position: relative;
  margin-top: 100px;
}

.PERInformation-table h4 {
  text-align: center;
}

.PERInformation-table .blt1 {
  position: absolute;
  float: right;
  top: 50px;
  right: 52px;
}

</style>